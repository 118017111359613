/* eslint-disable max-statements */
/* eslint-disable no-console */
import React from 'react';
import ReactDOM from 'react-dom';
import { getDomainFromAPI, getAPIURL } from '@jotforminc/request-layer';
import {
  getUserInfo,
  loadJS,
  initGoogleSignin,
  initFBSignin,
  initMicrosoftSignin,
  initSalesforceSignin,
  resizeWindow
} from '@jotforminc/utils';
import { isEnterprise } from '@jotforminc/enterprise-utils';
import { Texts, prepareLoginFlowDefaultGreetings } from '@jotforminc/constants';

import LoginFlow from '../components/LoginFlow';
import {
  forceUserSocialLogin,
  createGoogleOneTapContainerEl
} from './loginSignup';
import '../styles/style.scss';
import '../temporary-duplicate/styles/SaveAndContinueLater/style.scss';
import renderStandaloneSocialTermsModal from './renderStandaloneSocialTermsModal';
import { getPrefillToken } from './helper';

export default class LoginFlowHelper {
  constructor() {
    this.makeFormAssignment = this.makeFormAssignment.bind(this);
  }

  // eslint-disable-next-line complexity
  init({
    user,
    productType,
    resourceID,
    formUser,
    verificationHash,
    resourceTitle,
    isHIPAA,
    forceLogin,
    forceUser,
    welcomeText,
    greetingMessage,
    hideGreetingMessageOnSignupOptions,
    greetingMessageOnSignupWithEmail,
    ignoreFormUser,
    greetingDescription,
    loginDescriptionText,
    wrapperClassName,
    initialScreen,
    showLogoOnSignupOptions,
    showLogoOnSignupWithEmail,
    appleSigninEnabled,
    includeConfirm,
    noDefaultWrapper,
    containerClass,
    noLoading,
    showFormOnSignupOptions,
    enableGoogleOneTap,
    buttonNames,
    socialInitData,
    horizontalSocialButtons,
    squareSocialButtonStyle,
    onUserLogin,
    onNavigationChange,
    onGoogleLoginClick,
    onGoogleOneTapPromptOpened,
    onFBLoginClick,
    onAppleLoginClick,
    shrinkConfirmOnMobile,
    shrinkConfirmHeight,
    signupPredefinedEmail,
    logoSource,
    firstShowThankyouModal,
    showSocialTermsStepAsModal,
    showEmailAuthStepAsModal,
    readonlyEmail,
    showJotformLogo,
    showSalesforceLogin,
    showEnterpriseLogin,
    hideSignUp,
    redirectURL,
    showUnusualSignupFlow,
    unusualSignupToken,
    unusualSignupCode,
    appName,
    swsoTestVariant,
    useSwsoButtons,
    isReact17,
    initiator,
    mode
  }) {
    this.user = user || null;
    this.resourceID = resourceID;
    this.productType = productType;
    this.verificationHash = verificationHash;
    this.forceLogin = forceLogin === 'true';
    this.greetingMessage = greetingMessage || (forceUser ? (Texts.SIGN_UP_NOW_NO_EXCLAMATION_MARK) : (resourceTitle || 'Form'));
    this.hideGreetingMessageOnSignupOptions = hideGreetingMessageOnSignupOptions || false;
    this.greetingMessageOnSignupWithEmail = greetingMessageOnSignupWithEmail || Texts.SIGN_UP_EMAIL;
    this.loginWelcomeText = welcomeText || Texts.WELCOME_BACK;
    this.loginPredefinedEmail = formUser ? formUser.email : '';
    this.signupPredefinedName = (formUser && formUser.name) ? formUser.name : '';
    this.signupPredefinedEmail = signupPredefinedEmail || this.loginPredefinedEmail;
    this.disableEmail = formUser && formUser.email;
    this.isHIPAA = isHIPAA;
    this.forceUser = forceUser || false;
    this.greetingDescription = greetingDescription || prepareLoginFlowDefaultGreetings();
    this.wrapperClassName = wrapperClassName;
    this.initialScreen = initialScreen;
    this.showLogoOnSignupOptions = showLogoOnSignupOptions || false;
    this.showLogoOnSignupWithEmail = showLogoOnSignupWithEmail || false;
    this.appleSigninEnabled = appleSigninEnabled || false;
    this.includeConfirm = includeConfirm || false;
    this.noDefaultWrapper = noDefaultWrapper || false;
    this.noLoading = noLoading || false;
    this.enableGoogleOneTap = enableGoogleOneTap || false;
    this.showFormOnSignupOptions = showFormOnSignupOptions || false;
    this.containerClass = containerClass || 'react-root';
    this.buttonNames = buttonNames || {};
    this.socialInitData = socialInitData || {};
    this.horizontalSocialButtons = horizontalSocialButtons || false;
    this.squareSocialButtonStyle = squareSocialButtonStyle || false;
    this.onUserLogin = onUserLogin || (f => f);
    this.onNavigationChange = onNavigationChange || (f => f);
    this.onGoogleLoginClick = onGoogleLoginClick || (f => f);
    this.onGoogleOneTapPromptOpened = onGoogleOneTapPromptOpened || (f => f);
    this.onFBLoginClick = onFBLoginClick || (f => f);
    this.onAppleLoginClick = onAppleLoginClick || (f => f);
    this.shrinkConfirmOnMobile = shrinkConfirmOnMobile || false;
    this.shrinkConfirmHeight = shrinkConfirmHeight || 750;
    this.logoSource = logoSource;
    this.firstShowThankyouModal = firstShowThankyouModal || false;
    this.showSocialTermsStepAsModal = showSocialTermsStepAsModal || false;
    this.showEmailAuthStepAsModal = showEmailAuthStepAsModal || false;
    this.useSwsoButtons = useSwsoButtons || false;
    this.readonlyEmail = readonlyEmail || false;
    this.showJotformLogo = showJotformLogo || false;
    this.showSalesforceLogin = showSalesforceLogin || false;
    this.showEnterpriseLogin = showEnterpriseLogin || false;
    this.hideSignUp = hideSignUp;
    this.redirectURL = redirectURL;
    this.showUnusualSignupFlow = showUnusualSignupFlow;
    this.unusualSignupToken = unusualSignupToken;
    this.unusualSignupCode = unusualSignupCode;
    this.appName = appName;
    this.swsoTestVariant = swsoTestVariant;
    this.mode = mode || 'fullScreen';
    this.isReact17 = isReact17;
    this.initiator = initiator;

    if (isEnterprise() && !loginDescriptionText) {
      this.loginDescriptionText = '';
    } else {
      this.loginDescriptionText = loginDescriptionText || prepareLoginFlowDefaultGreetings();
    }

    if (!noLoading) ReactDOM.render(<div style={{ height: '30px' }} className="lding">Loading</div>, this.reactRoot);

    Promise.resolve()
      .then(() => (this.resourceID ? getUserInfo(this.resourceID, this.productType) : {}))
      .then(resp => {
        if (resp.user && (!ignoreFormUser || resp.userType !== 'FORM_USER')) {
          const baseURL = getDomainFromAPI(getAPIURL());
          const generateRedirectURL = path => `${baseURL}/${path}${window.location.search}`;
          switch (productType) {
            case 'draft':
              break;
            case 'form':
              // lets make a fake redirect, because user is already logged in but our server doesn't know it :(
              const prefillToken = getPrefillToken();
              window.location.href = generateRedirectURL(`${resourceID}${prefillToken ? `/prefill/${prefillToken}` : ''}`);
              break;
            default:
              window.location.href = generateRedirectURL(`${productType.toLowerCase()}/${resourceID}`);
              break;
          }
        } else {
          ReactDOM.unmountComponentAtNode(this.reactRoot);
          this.initAssigneeFlow();
        }
      }).catch(() => {
        ReactDOM.unmountComponentAtNode(this.reactRoot);
        this.initAssigneeFlow();
      });

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.get('registrationType') === 'oauth') {
      window.addEventListener('load', resizeWindow);
    }
  }

  get reactRoot() {
    let rootEl = document.querySelector(`.${this.containerClass}`);
    if (!rootEl) {
      rootEl = document.createElement('div');
      rootEl.className = `${this.containerClass}`;
      document.body.appendChild(rootEl);
    }
    return rootEl;
  }

  makeFormAssignment = (user, { newUser, onUserLogin } = {}) => {
    const skipRedirection = ((this && this.onUserLogin) || onUserLogin)(user, { newUser });
    const promise = skipRedirection && skipRedirection.then ? skipRedirection : Promise.resolve(skipRedirection);

    const redirect = () => {
      window.location.reload();
      const urlParams = new URLSearchParams(window.location.search);
      if (urlParams.get('jotform_pwa') && urlParams.get('saclEmbed')) {
        window.parent.location.reload();
      }
    };

    promise
      .then(skipRedirection_ => (skipRedirection_ !== true) && redirect())
      .catch(error => {
        console.error(error);
        redirect();
      });

    // fetch(`${this.apiURL}/form/${this.formID}/publicAssign`, { method: 'POST' });
  };

  initAssigneeFlow = () => {
    const wrapperClassName = [
      this.noDefaultWrapper ? '' : 'wwt',
      this.wrapperClassName
    ].filter(val => val).join(' ');

    ReactDOM.render(
      <div className={wrapperClassName}>
        <div className={this.noDefaultWrapper ? '' : 'wwt-inner'}>
          <LoginFlow
            user={this.user}
            mode={this.mode}
            showUnusualSignupFlow={this.showUnusualSignupFlow}
            unusualSignupToken={this.unusualSignupToken}
            unusualSignupCode={this.unusualSignupCode}
            apiURL={getAPIURL()}
            isHIPAA={this.isHIPAA}
            forceUser={this.forceUser}
            logoSource={this.logoSource}
            forceLogin={this.forceLogin}
            resourceID={this.resourceID}
            productType={this.productType}
            buttonNames={this.buttonNames}
            disableEmail={this.disableEmail}
            navigationWrapper={this.reactRoot}
            initialScreen={this.initialScreen}
            includeConfirm={this.includeConfirm}
            socialInitData={this.socialInitData}
            horizontalSocialButtons={this.horizontalSocialButtons}
            squareSocialButtonStyle={this.squareSocialButtonStyle}
            greetingMessage={this.greetingMessage}
            hideGreetingMessageOnSignupOptions={this.hideGreetingMessageOnSignupOptions}
            greetingMessageOnSignupWithEmail={this.greetingMessageOnSignupWithEmail}
            verificationHash={this.verificationHash}
            loginWelcomeText={this.loginWelcomeText}
            appleSigninEnabled={this.appleSigninEnabled}
            enableGoogleOneTap={this.enableGoogleOneTap}
            shrinkConfirmHeight={this.shrinkConfirmHeight}
            greetingDescription={this.greetingDescription}
            loginPredefinedEmail={this.loginPredefinedEmail}
            loginDescriptionText={this.loginDescriptionText}
            shrinkConfirmOnMobile={this.shrinkConfirmOnMobile}
            signupPredefinedEmail={this.signupPredefinedEmail}
            signupPredefinedName={this.signupPredefinedName}
            firstShowThankyouModal={this.firstShowThankyouModal}
            showLogoOnSignupOptions={this.showLogoOnSignupOptions}
            showLogoOnSignupWithEmail={this.showLogoOnSignupWithEmail}
            showFormOnSignupOptions={this.showFormOnSignupOptions}
            showEmailAuthStepAsModal={this.showEmailAuthStepAsModal}
            useSwsoButtons={this.useSwsoButtons}
            showSocialTermsStepAsModal={this.showSocialTermsStepAsModal}
            readonlyEmail={this.readonlyEmail}
            onUserLogin={this.makeFormAssignment}
            onFBLoginClick={this.onFBLoginClick}
            onAppleLoginClick={this.onAppleLoginClick}
            onNavigationChange={this.onNavigationChange}
            onGoogleLoginClick={this.onGoogleLoginClick}
            showJotformLogo={this.showJotformLogo}
            showSalesforceLogin={this.showSalesforceLogin}
            showEnterpriseLogin={this.showEnterpriseLogin}
            hideSignUp={this.hideSignUp}
            redirectURL={this.redirectURL}
            appName={this.appName}
            swsoTestVariant={this.swsoTestVariant}
            isReact17={this.isReact17}
            initiator={this.initiator}
          />
        </div>
      </div>, this.reactRoot
    );
  };

  createSocialTermsSecretManager = () => {
    let socialTermsSecret = {};

    const get = key => socialTermsSecret[key];
    const set = (key, val) => { socialTermsSecret[key] = val; };
    const removeAll = () => { socialTermsSecret = {}; };

    return {
      get,
      set,
      removeAll
    };
  };

  socialLoginRequest = (
    type,
    token,
    config,
    socialLogin2FAResponseCallback,
    errorCallback,
    socialLoginVerificationHash = '',
    socialLoginEAResponseCallback,
    idToken
  ) => {
    const { onSocialTermsRequired } = config;
    const newGoogleFlow = type === 'google' ? 1 : 0;
    const authToken = idToken || token;

    forceUserSocialLogin(
      type,
      authToken,
      response => {
        const { data: { newUser, user } } = response;
        this.makeFormAssignment(user, {
          newUser,
          onUserLogin: config.onUserLogin
        });
      },
      errorCallback,
      config.appName,
      response => {
        this.init({
          ...config,
          ...(config.renderValidationStepsAsModal && { mode: 'modal' }), // for new header
          initialScreen: 'socialInit',
          socialInitData: { type, token, response },
          noDefaultWrapper: true
        });
        if (socialLogin2FAResponseCallback) socialLogin2FAResponseCallback();
      },
      undefined,
      undefined,
      config.buttonNames,
      socialLoginVerificationHash,
      onSocialTermsRequired,
      true, // enable social terms
      response => {
        this.init({
          ...config,
          initialScreen: 'socialInit',
          showEmailAuthStepAsModal: true,
          socialInitData: { type, token, response }
        });
        if (socialLoginEAResponseCallback) socialLoginEAResponseCallback();
      },
      undefined,
      newGoogleFlow
    );
  };

  initGoogle = (config = {}, socialLogin2FAResponseCallback, errorCallback = f => f, socialLoginEAResponseCallback) => {
    const { onSocialLoginStart = f => f } = config;
    const client = initGoogleSignin(response => {
      const googleLoginThunk = (socialLoginVerificationHash, idToken) => this.socialLoginRequest(
        'google',
        response.code,
        config,
        socialLogin2FAResponseCallback,
        errorCallback,
        socialLoginVerificationHash,
        socialLoginEAResponseCallback,
        idToken
      );
      onSocialLoginStart({ socialSignupThunk: googleLoginThunk });
      googleLoginThunk();
    });
    if (client) {
      client.requestCode();
    }
  };

  handleGoogleClick = (config = {}, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback) => {
    if (!window.google) {
      loadJS('google-jssdk-new', 'https://accounts.google.com/gsi/client', () => {
        this.initGoogle(config, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback);
      });
      return;
    }
    this.initGoogle(config, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback);
  };

  initFacebook = (config, socialLogin2FAResponseCallback, errorCallback = f => f, socialLoginEAResponseCallback) => {
    const { onSocialLoginStart = f => f } = config;
    const fbLogin = initFBSignin();
    if (!fbLogin) return;
    fbLogin(({ status, authResponse }) => {
      if (status === 'connected' && authResponse) {
        const facebookLoginThunk = socialLoginVerificationHash => {
          this.socialLoginRequest('facebook', authResponse.accessToken, config, socialLogin2FAResponseCallback, errorCallback, socialLoginVerificationHash, socialLoginEAResponseCallback);
        };
        onSocialLoginStart({ socialSignupThunk: facebookLoginThunk });
        facebookLoginThunk();
      } else {
        errorCallback(status);
      }
    }, { scope: 'public_profile,email' });
  };

  handleFBClick = (config, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback) => {
    if (!window.FB) {
      loadJS('facebook-jssdk', 'https://connect.facebook.net/en_US/sdk.js', () => {
        this.initFacebook(config, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback);
      });
      return;
    }
    this.initFacebook(config, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback);
  };

  initMicrosoft = (config, socialLogin2FAResponseCallback, errorCallback = f => f, socialLoginEAResponseCallback) => {
    const { onSocialLoginStart = f => f } = config;
    const msLogin = initMicrosoftSignin();
    if (!msLogin) return;
    msLogin.then(({ accessToken, idToken }) => {
      if (accessToken && idToken) {
        const multipleTokens = JSON.stringify({ idToken, accessToken });
        const microsoftLoginThunk = socialLoginVerificationHash => {
          this.socialLoginRequest('microsoft', multipleTokens, config, socialLogin2FAResponseCallback, errorCallback, socialLoginVerificationHash, socialLoginEAResponseCallback);
        };
        onSocialLoginStart({ socialSignupThunk: microsoftLoginThunk });
        microsoftLoginThunk();
      } else {
        errorCallback();
      }
    }).catch(errorCallback);
  };

  handleMSClick = (config, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback) => {
    if (!window.msal) {
      loadJS('microsoft-jssdk', 'https://cdn.jotfor.ms/js/msal/msal-browser.js', () => {
        this.initMicrosoft(config, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback);
      });
      return;
    }
    this.initMicrosoft(config, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback);
  };

  initSalesforce = (config, socialLogin2FAResponseCallback, errorCallback = f => f, socialLoginEAResponseCallback) => {
    const { onSocialLoginStart = f => f } = config;
    const sLogin = initSalesforceSignin();
    if (!sLogin) return;
    sLogin.then(({ code, token }) => {
      if (code && token) {
        const multipleTokens = JSON.stringify({ code, token });
        const salesforceLoginThunk = socialLoginVerificationHash => {
          this.socialLoginRequest('salesforce', multipleTokens, config, socialLogin2FAResponseCallback, errorCallback, socialLoginVerificationHash, socialLoginEAResponseCallback);
        };
        onSocialLoginStart({ socialSignupThunk: salesforceLoginThunk });
        salesforceLoginThunk();
      } else {
        errorCallback();
      }
    }).catch(errorCallback);
  };

  handleSalesforceClick = (config, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback) => {
    this.initSalesforce(config, socialLogin2FAResponseCallback, errorCallback, socialLoginEAResponseCallback);
  };

  initGoogleOneTap = (config, socialLogin2FAResponseCallback, socialLoginEAResponseCallback) => {
    loadJS('googleOneTap', 'https://accounts.google.com/gsi/client', () => {
      if (window?.preventHeaderGoogleOneTap) {
        return true;
      }

      if (typeof window.GOOGLE_SIGNON === 'undefined') {
        return true;
      }

      if (typeof window.JOTFORM_ENV === 'undefined' || window.JOTFORM_ENV === 'ENTERPRISE' || window.JOTFORM_ENV === 'TESTING' || window.JOTFORM_ENV === 'DEVELOPMENT') {
        return true;
      }

      let accountType;

      if (typeof window.app?.user?.get === 'function') {
        accountType = window.app.user.get('account_type');
      } else if (window.user) {
        accountType = window.user.account_type;
      } else if (window.userData) {
        accountType = window.userData.account_type;
      }

      if (accountType && accountType.name !== 'GUEST') {
        return true;
      }

      if (document.querySelector('.removeAuthButtons')) {
        return true;
      }

      createGoogleOneTapContainerEl();

      window.google.accounts.id.initialize({
        client_id: window.GOOGLE_SIGNON,
        cancel_on_tap_outside: false,
        prompt_parent_id: 'oneTapLoginArea',
        callback: googleUser => {
          const { onSocialLoginStart = f => f } = config;
          const token = googleUser.credential;
          const googleOneTapLoginThunk = socialLoginVerificationHash => this.socialLoginRequest(
            'googleOneTap',
            token,
            config,
            socialLogin2FAResponseCallback,
            null,
            socialLoginVerificationHash,
            socialLoginEAResponseCallback
          );
          onSocialLoginStart({ socialSignupThunk: googleOneTapLoginThunk });
          googleOneTapLoginThunk();
        },
        state_cookie_domain: 'jotform.com'
      });
      window.google.accounts.id.prompt(notification => {
        if (notification.isNotDisplayed() || notification.isSkippedMoment()) {
          console.log('GoogleOnetap Skipped', notification.getNotDisplayedReason());
        } else if (notification.isDisplayMoment() && config.onGoogleOneTapPromptOpened) {
          config.onGoogleOneTapPromptOpened();
        }
      });
    });
  };

  renderStandaloneSocialTermsModal = (...props) => {
    renderStandaloneSocialTermsModal(...props);
  };
}
