/* eslint-disable max-statements */
import React from 'react';
import ReactDOM from 'react-dom';
import classNames from 'classnames';
import { getAPIURL, getURLDomain } from '@jotforminc/request-layer';
import LoginFlow from '../components/LoginFlow';
import { getEnterpriseConfigs } from '../api';

/**
 * TODO:
 * We can get this data from api/ShareAccessController.
 *
 * But the better solution would be handle this with the
 * other TODO where this variable is used.
 */
const DATA_PRODUCTS = [
  'REPORT',
  'SHEETS',
  'INBOX',
  'PDF-EDITOR'
];

export default class EnterpriseLoginFlowHelper {
  // eslint-disable-next-line complexity
  async init({
    onLoginClick,
    user,
    productType,
    resourceID,
    formUser,
    verificationHash,
    isHIPAA,
    forceLogin,
    forceUser,
    wrapperClassName,
    initialScreen,
    logoSource,
    includeConfirm,
    noDefaultWrapper,
    containerClass,
    noLoading,
    buttonNames,
    onUserLogin,
    onNavigationChange,
    shrinkConfirmOnMobile,
    shrinkConfirmHeight,
    firstShowThankyouModal,
    showSocialTermsStepAsModal,
    showEmailAuthStepAsModal,
    signupPredefinedEmail,
    readonlyEmail,
    hideSignUp,
    resourceTitle,
    ssoLoginOptions = [],
    redirectURL,
    forceSSO
  }) {
    this.onLoginClick = onLoginClick;
    this.user = user || null;
    this.resourceID = resourceID;
    this.productType = productType;
    this.resourceTitle = resourceTitle;
    this.verificationHash = verificationHash;
    this.forceLogin = forceLogin === 'true';
    this.isHIPAA = isHIPAA;
    this.forceUser = forceUser || false;
    this.wrapperClassName = wrapperClassName;
    this.initialScreen = initialScreen;
    this.logoSource = logoSource;
    this.includeConfirm = includeConfirm || false;
    this.noDefaultWrapper = noDefaultWrapper || false;
    this.noLoading = noLoading || false;
    this.containerClass = containerClass || 'react-root';
    this.buttonNames = buttonNames || {};
    this.onUserLogin = onUserLogin || (f => f);
    this.onNavigationChange = onNavigationChange || (f => f);
    this.shrinkConfirmOnMobile = shrinkConfirmOnMobile || false;
    this.shrinkConfirmHeight = shrinkConfirmHeight || 750;
    this.firstShowThankyouModal = firstShowThankyouModal || false;
    this.showSocialTermsStepAsModal = showSocialTermsStepAsModal || false;
    this.showEmailAuthStepAsModal = showEmailAuthStepAsModal || false;
    this.loginPredefinedEmail = formUser ? formUser.email : '';
    this.signupPredefinedEmail = signupPredefinedEmail || this.loginPredefinedEmail;
    this.signupPredefinedName = (formUser && formUser.name) ? formUser.name : '';
    this.redirectURL = redirectURL;
    this.forceSSO = forceSSO;

    // TODO: isMobileApp check from the user agent is used on
    // different projects. We better commonize this check
    // as a utils function for future proofing
    this.isMobileApp = global?.navigator?.userAgent?.includes('JotForm Mobile')
      || global?.navigator?.userAgent?.includes('JFCEMobile');

    /**
     * hideSignUp was never passed down here before our updates.
     * just to keep things on the safe side, lets used the
     * passed value only on the ssoNormalLoginEnabled env.
     */
    this.hideSignUp = hideSignUp ?? true;
    this.ssoLoginOptions = ssoLoginOptions;
    this.ssoNormalLoginEnabled = false;

    try {
      /**
       * TODO:
       * sso+normal login flag will be removed after it is enabled for all
       * it seems like the login flow only need the customizations for
       * OOS permissions, maybe we can connect here to a more limited backend
       * for this check.
       */
      const {
        content: {
          PUBLIC_DATA_SHARING_ENABLED = false,
          FORM_BUILDER_OOS_SHARE_ENABLED = false,
          SSO_AND_NORMAL_LOGIN_ENABLED = false
        }
      } = await getEnterpriseConfigs();

      // Show signup options for OOS sharing enabled servers
      if (
        (DATA_PRODUCTS.includes(productType) && PUBLIC_DATA_SHARING_ENABLED)
        || (productType === 'FORM-BUILDER' && FORM_BUILDER_OOS_SHARE_ENABLED)
      ) {
        this.hideSignUp = false;
      }

      // SSO + Normal login feature flag
      if (SSO_AND_NORMAL_LOGIN_ENABLED) {
        this.ssoNormalLoginEnabled = true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log('err', err);
    }

    this.initEnterpriseLoginFlow();

    this.readonlyEmail = readonlyEmail || false;
    if (this.isPopup) {
      this.handlePopupWindow();
    }
  }

  get reactRoot() {
    let rootEl = document.querySelector(`.${this.containerClass}`);
    if (!rootEl) {
      rootEl = document.createElement('div');
      rootEl.className = `${this.containerClass}`;
      document.body.appendChild(rootEl);
    }
    return rootEl;
  }

  get wrapperClassNames() {
    return classNames(this.wrapperClassName, {
      wwt: !this.noDefaultWrapper
    });
  }

  get innerClassnames() {
    const useWrapperClass = !this.noDefaultWrapper && !this.isPopup;

    return classNames({
      'wwt-inner': useWrapperClass,
      'enterprise-wwt-inner': this.ssoNormalLoginEnabled && useWrapperClass
    });
  }

  get isPopup() {
    if (window.location.pathname.includes('/login')) {
      const queryParameters = new URLSearchParams(window.location.search);
      const isPopup = queryParameters.get('popup') ? queryParameters.get('popup') : false;

      return isPopup === '1';
    }

    return false;
  }

  get optionalProps() {
    if (!this.ssoNormalLoginEnabled) {
      return {};
    }

    const {
      resourceTitle,
      productType
    } = this;

    const optionalProps = {};

    if (resourceTitle) {
      optionalProps.loginWelcomeText = resourceTitle;
    } else if (window.location.pathname.includes('/login')) {
      optionalProps.loginWelcomeText = '';
    }

    if (productType) {
      optionalProps.loginDescriptionText = 'Log in to access this resource';
    }

    return optionalProps;
  }

  handlePopupWindow = () => {
    window.resizeTo(600, 680);
  };

  handleOnUserLogin = (user, { newUser, onUserLogin } = {}) => {
    if (window.location.pathname.includes('/login')) {
      const queryParameters = new URLSearchParams(window.location.search);
      const redirectPage = queryParameters.get('rp') ? queryParameters.get('rp') : '';

      if (
        this.isMobileApp
        && this.ssoNormalLoginEnabled
        && user?.appKey
      ) {
        window.ReactNativeWebView?.postMessage(JSON.stringify({
          appKey: user.appKey,
          type: 'loginSuccess'
        }));
      } else {
        window.location.replace((redirectPage && getURLDomain(redirectPage) === window.location.hostname) ? redirectPage : `${window.location.origin}/myforms`);
      }
    } else {
      const skipRedirection = ((this && this.onUserLogin) || onUserLogin)(user, { newUser });
      const promise = skipRedirection && skipRedirection.then ? skipRedirection : Promise.resolve(skipRedirection);
      const redirect = () => {
        if (window.location.pathname !== '/order-form-creator/') {
          window.location.reload();
        }
      };
      promise
        .then(skipRedirection_ => (skipRedirection_ !== true) && redirect())
        .catch(error => {
          // eslint-disable-next-line no-console
          console.error(error);
          redirect();
        });
    }
  };

  initEnterpriseLoginFlow = () => {
    ReactDOM.render(
      <div className={this.wrapperClassNames}>
        <div
          className={this.innerClassnames}
          style={this.isPopup ? {
            maxWidth: '500px',
            width: '100%',
            padding: '0 10px'
          } : {}}
        >
          <LoginFlow
            user={this.user}
            mode="fullScreen"
            apiURL={getAPIURL()}
            isHIPAA={this.isHIPAA}
            forceUser={this.forceUser}
            forceLogin={this.forceLogin}
            resourceID={this.resourceID}
            productType={this.productType}
            buttonNames={this.buttonNames}
            navigationWrapper={this.reactRoot}
            initialScreen={this.initialScreen}
            logoSource={this.logoSource}
            includeConfirm={this.includeConfirm}
            verificationHash={this.verificationHash}
            shrinkConfirmHeight={this.shrinkConfirmHeight}
            shrinkConfirmOnMobile={this.shrinkConfirmOnMobile}
            firstShowThankyouModal={this.firstShowThankyouModal}
            signupPredefinedEmail={this.signupPredefinedEmail}
            signupPredefinedName={this.signupPredefinedName}
            showEmailAuthStepAsModal={this.showEmailAuthStepAsModal}
            showSocialTermsStepAsModal={this.showSocialTermsStepAsModal}
            onUserLogin={this.handleOnUserLogin}
            onNavigationChange={this.onNavigationChange}
            readonlyEmail={this.readonlyEmail}
            hideSignUp={this.hideSignUp}
            ssoLoginOptions={this.ssoNormalLoginEnabled ? this.ssoLoginOptions : []}
            ssoNormalLoginEnabled={this.ssoNormalLoginEnabled}
            redirectURL={this.redirectURL}
            forceShowBack={true}
            forceSSO={this.forceSSO}
            // Optional Props that should not mess with default props if not needed
            {...this.optionalProps}
          />
        </div>
      </div>, this.reactRoot
    );
  };
}
